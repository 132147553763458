<script>
import { mapState } from 'vuex';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { MuiAlgoliaList } from '@emobg/vue-internal';
import { aclService } from '@emobg/access-utils';
import { DELAY } from '@emobg/web-utils';
import * as BookingCostAllocationModule from '@/stores/CostAllocation/Booking/BookingCostAllocationModule';
import { userData } from '@/stores/User/UserData/UserDataMapper';
import { fetchCSOperator } from '@/stores/CSOperator/CSOperatorMapper';
import { DEFAULT_HITS_PER_PAGE } from '@/constants/algoliaTable';

// Components
import EditBookingTimeModal from '@/components/MyBookings/modals/EditBookingTimeModal';
import CancelBookingModal from '@/components/MyBookings/modals/CancelBookingModal';
import EditDropOffBookingModal from '@/components/MyBookings/modals/EditDropOffBookingModal';
import EditBookingPeopleModal from '@/components/MyBookings/modals/EditBookingPeopleModal';

// Helpers
import { VIEW_BOOKINGS_COMPANY_EMPLOYEES, VIEW_OWN_BEHALF_EMPLOYEE_BOOKINGS } from '@/constants/permissions';
import { genericError } from '@/utils/publicImages';
import { useBookingModal } from '@/components/MyBookings/modals/composable/BookingModal';
import { GTMModalEvents } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { useAlgolia } from '@/composable/Algolia/useAlgolia';
import { employeePreBookingsColumns, employeeRequestBookingsFacets } from './helpers/tableConfig';

import {
  getCircles,
  getEmployeeUuids,
  getPermissionsOnBooking,
} from './helpers/permissions';

import { canAddVehicleUsers } from './helpers/csOperatorValidations';

import {
  employeeBookingsFilters,
  getEditBookingReturnLocation,
  getEditBookingTime,
  toEditBookingPeopleData,
} from './helpers/algolia';
import { LABELS } from './constants/labels.const';

export default {
  name: 'EmployeeBookingsRequestsComponent',
  components: {
    EditBookingTimeModal,
    CancelBookingModal,
    MuiAlgoliaList,
    EditDropOffBookingModal,
    EditBookingPeopleModal,
  },
  setup() {
    const {
      apiKey,
      algoliaConfig,
      initAlgolia,
      refreshAlgoliaTable,
      getAlgoliaLabels,
    } = useAlgolia();
    const {
      isModalOpen: isEditTimeModalOpen,
      openModal: openEditTimeModal,
      closeModal: closeEditTimeModal,
      bookingDataModal: editTimeBooking,
    } = useBookingModal();
    const {
      isModalOpen: isCancelBookingModalOpen,
      openModal: openCancelBookingModal,
      closeModal: closeCancelBookingModal,
      bookingDataModal: cancelBooking,
    } = useBookingModal();
    const {
      isModalOpen: isEditDropOffBookingModalOpen,
      openModal: openEditDropOffBookingModal,
      closeModal: closeEditDropOffBookingModal,
      bookingDataModal: dropOffBooking,
    } = useBookingModal();
    const { trackModalView, trackPageView } = useTrackingGTM();
    const { notifyInfo, notifyError } = useNotifications();
    const app = document.getElementById('app');
    return {
      isEditTimeModalOpen,
      openEditTimeModal,
      closeEditTimeModal,
      editTimeBooking,
      isCancelBookingModalOpen,
      openCancelBookingModal,
      closeCancelBookingModal,
      cancelBooking,
      isEditDropOffBookingModalOpen,
      openEditDropOffBookingModal,
      closeEditDropOffBookingModal,
      dropOffBooking,
      trackModalView,
      trackPageView,
      notifyInfo,
      notifyError,
      algoliaConfig,
      initAlgolia,
      apiKey,
      refreshAlgoliaTable,
      app,
      getAlgoliaLabels,
      LABELS,
    };
  },
  data() {
    return {
      isLoading: false,
      isListLoading: false,
      algoliaFilters: null,
      cache: {},
      modals: {
        editBookingPeople: {
          isOpen: false,
        },
      },
    };
  },
  computed: {
    userData,
    ...mapState(
      BookingCostAllocationModule.NAMESPACE,
      { costAllocationList: state => get(state, 'COST_ALLOCATION_DATA.costAllocationList', []) },
    ),
  },

  async created() {
    this.isLoading = true;
    const userEmail = get(this, 'userData.email');
    this.genericErrorIllustration = genericError;
    this.ALGOLIA_QUERY_PARAMETERS = {
      hitsPerPage: DEFAULT_HITS_PER_PAGE,
    };
    this.TABLE_LABELS = this.getAlgoliaLabels(this.LABELS);
    this.TABLE_FACETS = employeeRequestBookingsFacets({ userEmail });
    this.ITEM_ACTIONS = [
      {
        label: this.$t('common.booking_dropdown.edit_time'),
        method: booking => {
          this.openEditTimeModal({ booking, transformer: getEditBookingTime });
          this.trackModalView({ modalName: GTMModalEvents.editEmployeePrebookingTime });
        },
        isVisible: context => {
          const { isEditable } = getPermissionsOnBooking(this, context, this.circles);
          return isEditable;
        },
      },
      {
        label: this.$t('common.booking_dropdown.edit_dropoff_location'),
        method: booking => {
          try {
            this.openEditDropOffBookingModal({ booking, transformer: getEditBookingReturnLocation });
            this.trackModalView({ modalName: GTMModalEvents.editEmployeePrebookingReturnLocation });
          } catch (error) {
            this.notifyError({
              text: this.t('notifications.whooops'),
              textAction: this.$t('buttons.ok'),
            });
          }
        },
        isVisible: context => {
          const { isEditable } = getPermissionsOnBooking(this, context, this.circles);
          return isEditable;
        },
      },
      {
        label: this.$t('buttons.edit_people_from_trip'),
        method: booking => {
          this.openAdditionalVehicleUsers(booking);
          this.trackModalView({ modalName: GTMModalEvents.editEmployeePrebookingPeople });
        },
        isVisible: preBooking => {
          const { canAddDrivers, canAddPassengers } = canAddVehicleUsers(this, preBooking, this.circles, this.operatorConfiguration);

          return (canAddDrivers || canAddPassengers);
        },
      },
      {
        label: this.$t('common.booking_dropdown.cancel_booking'),
        class: 'emobg-color-danger',
        method: booking => {
          const bookingTransformer = ({ uuid }) => ({ uuid, isBookingRequest: true });

          try {
            this.openCancelBookingModal({ booking, transformer: bookingTransformer });
            this.trackModalView({ modalName: GTMModalEvents.cancelEmployeePrebooking });
          } catch (error) {
            this.notifyError({
              text: this.t('notifications.whooops'),
              textAction: this.$t('buttons.ok'),
            });
          }
        },
        isVisible: context => {
          const { isCancelable } = getPermissionsOnBooking(this, context, this.circles);
          return isCancelable;
        },
      },
    ];
    this.TABLE_CONFIG = employeePreBookingsColumns(this.notifyInfo, this.costAllocationList, this.ITEM_ACTIONS);

    // NOTE: We should filter by companyUuid when prebookings will be open to Europcar operators
    const csOperatorUuid = get(this, 'userData.company.dedicated_fleet_cs_operator');
    if (csOperatorUuid) {
      this.dedicatedOperator = await this.fetchCSOperator(csOperatorUuid);
    }

    this.operatorConfiguration = get(this, 'dedicatedOperator.configuration');

    const hasRegularViewBookingsPermission = aclService.hasUserPermissions(
      [VIEW_BOOKINGS_COMPANY_EMPLOYEES],
      [VIEW_OWN_BEHALF_EMPLOYEE_BOOKINGS],
    );

    let circlesEmployees;
    if (!hasRegularViewBookingsPermission) {
      const circleUuids = aclService.getCirclesByPermission(VIEW_BOOKINGS_COMPANY_EMPLOYEES);
      this.circles = await getCircles(circleUuids);
      circlesEmployees = getEmployeeUuids(this.circles);
    }

    const userUuid = get(this, 'userData.uuid');
    const onBehalfUserUuid = aclService.hasUserPermissions(VIEW_OWN_BEHALF_EMPLOYEE_BOOKINGS) ? userUuid : null;

    this.algoliaFilters = employeeBookingsFilters({
      csOperatorUuid,
      circlesEmployees,
      isPreBooking: true,
      ownerUuid: onBehalfUserUuid,
      creatorUuid: onBehalfUserUuid,
    });
    await this.initAlgolia('prebookings', this.algoliaFilters);

    this.isLoading = false;
  },
  methods: {
    isEmpty,
    fetchCSOperator,
    openAdditionalVehicleUsers(booking) {
      const {
        canAddDrivers,
        canAddPassengers,
      } = canAddVehicleUsers(this, booking, this.circles, this.operatorConfiguration);

      this.modals.editBookingPeople = {
        ...toEditBookingPeopleData({
          ...booking,
          isPreBooking: true,
        }),
        isAdditionalDriverEnabled: Boolean(canAddDrivers),
        isAdditionalPassengersEnabled: Boolean(canAddPassengers),
        isPreBooking: true,
        isOpen: true,
      };
    },
    closeTimeModal(isEdited) {
      this.closeEditTimeModal();
      this.trackPageView();
      if (isEdited) {
        this.isListLoading = true;
        setTimeout(() => {
          this.refreshAlgoliaTable(this.$refs.muiAlgoliaEmployeePreBookingsList, DELAY.medium);
          this.isListLoading = false;
        }, DELAY.medium);
      }
    },
    closeCancelModal(isCanceling) {
      this.closeCancelBookingModal();
      this.trackPageView();
      if (isCanceling) {
        this.isListLoading = true;
        setTimeout(() => {
          this.refreshAlgoliaTable(this.$refs.muiAlgoliaEmployeePreBookingsList, DELAY.medium);
          this.isListLoading = false;
        }, DELAY.medium);
      }
    },
    closeEditDropOffModal({ isEditing }) {
      this.closeEditDropOffBookingModal();
      this.trackPageView();
      if (isEditing) {
        this.isListLoading = true;
        setTimeout(() => {
          this.refreshAlgoliaTable(this.$refs.muiAlgoliaEmployeePreBookingsList, DELAY.medium);
          this.isListLoading = false;
        }, DELAY.medium);
      }
    },
    closeEditPeopleModal({ isEdited }) {
      this.modals.editBookingPeople.isOpen = false;
      this.trackPageView();
      if (isEdited) {
        this.isListLoading = true;
        setTimeout(() => {
          this.refreshAlgoliaTable(this.$refs.muiAlgoliaEmployeePreBookingsList, DELAY.medium);
          this.isListLoading = false;
        }, DELAY.medium);
      }
    },
  },
};
</script>
<template>
  <div class="EmployeeBookingsRequestsComponent container pt-4">
    <h1 class="emobg-font-large">
      {{ $t('business_profile.employee_prebookings.title') }}
    </h1>
    <h2 class="emobg-font-medium mb-5">
      {{ $t('business_profile.employee_prebookings.subtitle') }}
    </h2>
    <ui-loader v-if="isLoading" />
    <div v-else>
      <figure
        v-if="isEmpty(algoliaFilters)"
        class="d-flex justify-content-center"
      >
        <img
          :src="genericErrorIllustration"
          class="w-50"
          data-test-id="employee_request_bookings_table-failed_image"
          alt="error"
        >
      </figure>
      <MuiAlgoliaList
        v-else
        ref="muiAlgoliaEmployeePreBookingsList"
        :loading="isListLoading"
        :config="algoliaConfig"
        :api-key="apiKey"
        :filters="algoliaFilters"
        :facets="TABLE_FACETS"
        :table-config="TABLE_CONFIG"
        :labels="TABLE_LABELS"
        :query-parameters="ALGOLIA_QUERY_PARAMETERS"
        :scroll-target="app"
        :no-data-label="FALLBACK_MESSAGE.dash"
        index="prebookings"
        class="EmployeePreBookingsList"
      />

      <EditBookingTimeModal
        v-if="isEditTimeModalOpen && editTimeBooking"
        :booking="editTimeBooking"
        :is-open="isEditTimeModalOpen"
        @on:close="closeTimeModal"
      />
      <CancelBookingModal
        v-if="isCancelBookingModalOpen && cancelBooking"
        :is-open="isCancelBookingModalOpen"
        :booking="cancelBooking"
        @on:close="closeCancelModal"
      />
      <EditDropOffBookingModal
        v-if="isEditDropOffBookingModalOpen && !isEmpty(dropOffBooking)"
        is-open="isEditDropOffBookingModalOpen"
        :booking="dropOffBooking"
        @on:close="closeEditDropOffModal"
      />
      <EditBookingPeopleModal
        v-model="modals.editBookingPeople.isOpen"
        v-bind="modals.editBookingPeople"
        @on:close="closeEditPeopleModal"
      />
    </div>
  </div>
</template>
