import capitalize from 'lodash/capitalize';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import reject from 'lodash/reject';
import isEmpty from 'lodash/isEmpty';

import {
  FALLBACK_MESSAGE, compactArray, getValue, isNilValue,
} from '@emobg/web-utils';
import { ALGOLIA_REFINEMENT_OPTIONS } from '@emobg/vue-internal';

import { useTranslations } from '@/composable/App/Translations';
import ClipboardComponent from '@/components/Clipboard/ClipboardComponent';
import { sortingTypes } from '@/constants/algoliaTable';

import AlgoliaTableItemActions from '@/components/AlgoliaTableItemActions/AlgoliaTableItemActions';
import BookingDateTemplate from '../components/BookingDateTemplate';
import BookingStatusTemplate from '../components/BookingStatusTemplate';
import BookingCreatorComponent from '../components/BookingCreatorComponent';
import BookingLinkLocationAddress from '../components/BookingLinkLocationAddress';
import BookingCostAllocations from '../components/BookingCostAllocations';
import { PREBOOKING_FIELDS } from '../constants/preBookingFields.const';
import { BOOKING_FIELDS } from '../constants/bookingFields.const';
import { isFinishedBooking, transformDuration } from './bookings';
import { creatorFacetSorter } from './algolia';

const { $t } = useTranslations();

const bookingsTranslationPrefix = 'business_profile.employee_bookings';
const requestBookingsTranslationPrefix = 'business_profile.employee_prebookings';

const commonFacets = ({ userEmail }) => ({
  creatorFacet: {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: $t(`${bookingsTranslationPrefix}.filters.creator_email`),
      attributeName: BOOKING_FIELDS.creatorEmail,
      transformValue: creator => (creator === userEmail
        ? $t(`${bookingsTranslationPrefix}.special_values.creator_email.created_by_me`)
        : creator),
      sortFacetsBy: (currentCreator, nextCreator) => creatorFacetSorter(currentCreator, nextCreator, userEmail),
    },
  },
});

export const employeeBookingsFacets = ({ userEmail }) => [
  commonFacets({ userEmail }).creatorFacet,
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: $t(`${bookingsTranslationPrefix}.filters.status`),
      attributeName: BOOKING_FIELDS.status,
      transformValue: status => $t(`booking_status.${status}`, capitalize(status)),
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.dateRange,
    props: {
      title: $t(`${bookingsTranslationPrefix}.filters.start`),
      attributeName: BOOKING_FIELDS.startTzTs,
      timezone: '',
      labels: {
        from: $t(`${bookingsTranslationPrefix}.filters.from`),
        to: $t(`${bookingsTranslationPrefix}.filters.to`),
      },
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.dateRange,
    props: {
      title: $t(`${bookingsTranslationPrefix}.filters.end`),
      attributeName: BOOKING_FIELDS.endTzTs,
      timezone: '',
      labels: {
        from: $t(`${bookingsTranslationPrefix}.filters.from`),
        to: $t(`${bookingsTranslationPrefix}.filters.to`),
      },
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: $t(`${bookingsTranslationPrefix}.filters.fleet`),
      attributeName: BOOKING_FIELDS.fleet,
      transformValue: fleet => $t(`constants.fleet_type.${fleet}`, capitalize(fleet)),
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: $t(`${bookingsTranslationPrefix}.filters.city`),
      attributeName: BOOKING_FIELDS.city,
      sortFacetsBy: [`${BOOKING_FIELDS.name}:${sortingTypes.asc}`],
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: $t(`${bookingsTranslationPrefix}.filters.location`),
      attributeName: BOOKING_FIELDS.location,
      sortFacetsBy: [`${BOOKING_FIELDS.name}:${sortingTypes.asc}`],
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: $t(`${bookingsTranslationPrefix}.filters.vehicle_category`),
      attributeName: BOOKING_FIELDS.vehicleCategory,
    },
  },
];

export const employeeBookingsColumns = ({ costAllocationList = [], isOperatorAllowingOneWay = false, rowActions }) => reject(
  compactArray([
    {
      title: $t(`${bookingsTranslationPrefix}.headers.date`),
      component: BookingDateTemplate,
      props: ({ start, end }) => ({
        startDate: start,
        endDate: end,
      }),
    },
    {
      title: $t(`${bookingsTranslationPrefix}.headers.model`),
      transformResult: result => `${result.vehicle_brand} ${result.vehicle_model}`,
    },
    {
      title: $t(`${bookingsTranslationPrefix}.headers.employee_name`),
      property: BOOKING_FIELDS.userName,
    },
    {
      title: $t(`${bookingsTranslationPrefix}.headers.status`),
      component: BookingStatusTemplate,
      props: result => ({ result }),
    },
    {
      title: isOperatorAllowingOneWay
        ? $t(`${bookingsTranslationPrefix}.headers.pick_up_location`)
        : $t(`${bookingsTranslationPrefix}.headers.location`),
      component: BookingLinkLocationAddress,
      props: ({ location_address: locationAddress, location: locationName }) => ({
        locationAddress,
        locationName,
      }),
      folded: true,
    },
    {
      title: $t(`${bookingsTranslationPrefix}.headers.destination_location`),
      component: BookingLinkLocationAddress,
      props: ({ destination_location: destionationLocation }) => ({
        locationAddress: destionationLocation.address,
        locationName: destionationLocation.name,
      }),
      folded: true,
      hidden: !isOperatorAllowingOneWay,
    },
    {
      title: $t(`${bookingsTranslationPrefix}.headers.employee_email`),
      property: BOOKING_FIELDS.employeeEmail,
      folded: true,
    },
    {
      title: $t(`${bookingsTranslationPrefix}.headers.employee_phone`),
      property: BOOKING_FIELDS.employeePhone,
      folded: true,
    },
    {
      title: $t(`${bookingsTranslationPrefix}.headers.fleet`),
      property: BOOKING_FIELDS.fleet,
      transformValue: value => $t(`constants.fleet_type.${value}`, capitalize(value)),
      folded: true,
    },
    {
      title: $t(`${bookingsTranslationPrefix}.headers.duration`),
      transformResult: result => (isEmpty(result.start) || isEmpty(result.end)
        ? FALLBACK_MESSAGE.dash
        : transformDuration(result.start, result.end)),
      folded: true,
    },
    {
      title: $t(`${bookingsTranslationPrefix}.headers.mileage`),
      transformResult: result => (isNilValue(result.mileage)
        ? FALLBACK_MESSAGE.dash
        : `${result.mileage} ${result.mileage_unit}`),
      folded: true,
    },
    {
      title: $t(`${bookingsTranslationPrefix}.headers.additional_driver`),
      property: BOOKING_FIELDS.additionalDriver,
      transformValue: additionalDriverValue => (isArray(additionalDriverValue)
        ? map(additionalDriverValue, 'email').join(', ')
        : FALLBACK_MESSAGE.dash),
      folded: true,
    },
    {
      title: $t(`${bookingsTranslationPrefix}.headers.cost_allocations`),
      component: BookingCostAllocations,
      props: ({ company_cost_allocations: costAllocationValue }) => ({
        costAllocationValue,
        costAllocationList,
      }),
      folded: true,
    },
    {
      title: $t(`${bookingsTranslationPrefix}.headers.real_date`),
      component: BookingDateTemplate,
      props: ({ real_start_tz: startDate, real_end_tz: endDate, status }) => ({
        startDate,
        endDate,
        isBookingFinished: isFinishedBooking(status),
      }),
      folded: true,
    },
    {
      title: $t(`${bookingsTranslationPrefix}.headers.vehicle_licence_plate`),
      property: BOOKING_FIELDS.vehiclePlate,
      folded: true,
    },
    {
      title: $t(`${bookingsTranslationPrefix}.headers.booking_creator`),
      component: BookingCreatorComponent,
      props: ({ creator = {} }) => ({
        fullName: creator.full_name,
        email: creator.email,
        masked: true,
      }),
      folded: true,
    },
    {
      title: '',
      component: AlgoliaTableItemActions,
      props: result => ({ algoliaContext: result, rowActions }),
    },
  ]),
  'hidden',
);

export const employeeRequestBookingsFacets = ({ userEmail }) => [
  commonFacets({ userEmail }).creatorFacet,
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: $t(`${requestBookingsTranslationPrefix}.filters.city`),
      attributeName: PREBOOKING_FIELDS.cityName,
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: $t(`${requestBookingsTranslationPrefix}.filters.parking`),
      attributeName: PREBOOKING_FIELDS.locationName,
    },
  },
];

export const employeePreBookingsColumns = (notifyInfo, costAllocationList = [], rowActions) => [
  {
    title: $t(`${requestBookingsTranslationPrefix}.labels.employee`),
    property: PREBOOKING_FIELDS.user,
    transformValue: user => `${user.first_name} ${user.last_name}`,
  },
  {
    title: $t('business_profile.employee_bookings.headers.date'),
    component: BookingDateTemplate,
    props: ({ start: startDate, end: endDate }) => ({
      startDate,
      endDate,
    }),
  },
  {
    title: $t(`${requestBookingsTranslationPrefix}.labels.pickup_location`),
    property: PREBOOKING_FIELDS.location,
    transformValue: location => getValue(location, 'name', FALLBACK_MESSAGE.dash),
  },
  {
    title: $t(`${requestBookingsTranslationPrefix}.labels.return_location`),
    property: PREBOOKING_FIELDS.destinationLocation,
    transformValue: location => getValue(location, 'name', FALLBACK_MESSAGE.dash),
  },
  {
    title: $t(`${requestBookingsTranslationPrefix}.labels.booking_request_id`),
    component: ClipboardComponent,
    props: (result = {}) => ({
      text: result.uuid,
      notification: ({ show }) => {
        if (show) {
          notifyInfo({
            text: $t(`${requestBookingsTranslationPrefix}.labels.booking_request_id_copied`),
            textAction: $t('buttons.ok'),
          });
        }
      },
    }),
    folded: true,
  },
  {
    title: $t(`${requestBookingsTranslationPrefix}.labels.email`),
    property: PREBOOKING_FIELDS.user,
    transformValue: user => getValue(user, 'email', FALLBACK_MESSAGE.dash),
    folded: true,
  },
  {
    title: $t(`${requestBookingsTranslationPrefix}.labels.phone`),
    property: PREBOOKING_FIELDS.user,
    transformValue: user => getValue(user, 'phone', FALLBACK_MESSAGE.dash),
    folded: true,
  },
  {
    title: $t(`${requestBookingsTranslationPrefix}.labels.duration`),
    property: PREBOOKING_FIELDS.bookingDuration,
    folded: true,
  },
  {
    title: $t(`${requestBookingsTranslationPrefix}.labels.city`),
    property: PREBOOKING_FIELDS.location,
    transformValue: location => getValue(location, 'city.name', FALLBACK_MESSAGE.dash),
    folded: true,
  },
  {
    title: $t(`${requestBookingsTranslationPrefix}.labels.type`),
    property: PREBOOKING_FIELDS.csBookingUseCase,
    transformValue: bookingUseCase => getValue(bookingUseCase, 'fleet', FALLBACK_MESSAGE.dash),
    folded: true,
  },
  {
    title: $t(`${requestBookingsTranslationPrefix}.labels.km`),
    property: PREBOOKING_FIELDS.distance,
    folded: true,
  },
  {
    title: $t(`${requestBookingsTranslationPrefix}.labels.vehicle`),
    property: PREBOOKING_FIELDS.vehicleCategory,
    transformValue: vehicleCategory => getValue(vehicleCategory, 'name', FALLBACK_MESSAGE.dash),
    folded: true,
  },
  {
    title: $t(`${bookingsTranslationPrefix}.headers.cost_allocations`),
    component: BookingCostAllocations,
    props: ({ company_cost_allocations: costAllocationValue }) => ({
      costAllocationValue,
      costAllocationList,
    }),
    folded: true,
  },
  {
    title: $t(`${bookingsTranslationPrefix}.headers.additional_driver`),
    property: BOOKING_FIELDS.additionalDriver,
    transformValue: additionalDriverValue => (isArray(additionalDriverValue)
      ? map(additionalDriverValue, 'email').join(', ')
      : FALLBACK_MESSAGE.dash),
    folded: true,
  },
  {
    title: $t(`${bookingsTranslationPrefix}.headers.booking_creator`),
    component: BookingCreatorComponent,
    props: ({ creator = {} }) => ({
      fullName: `${creator.first_name} ${creator.last_name}`,
      email: creator.email,
    }),
    folded: true,
  },
  {
    title: '',
    component: AlgoliaTableItemActions,
    props: result => ({ algoliaContext: result, rowActions }),
  },
];
