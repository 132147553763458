<script>
import { LOG_TYPE, logger } from '@emobg/web-utils';
import { copySelectedText, selectText, unselectText } from '@/utils/clipboard';

export default {
  name: 'ClipboardComponent',
  props: {
    text: {
      type: String,
      required: true,
    },
    notification: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    onClickIcon() {
      try {
        const isSelected = selectText(this.$refs.text);
        if (isSelected) {
          this.notification({ show: true });
          copySelectedText();
          unselectText();
        }
      } catch (error) {
        logger.message(`There was a problem copying the text: ${error.message}`, LOG_TYPE.warning);
      }

      this.notification({ show: false });
    },
  },
};
</script>

<template>
  <div class="d-flex align-items-center">
    <span
      ref="text"
      class="pr-1"
      data-test-id="clipboard-component--text"
    >
      {{ text }}
    </span>
    <ui-icon
      ref="icon"
      :icon="ICONS.copy"
      :size="ICONS_SIZES.xSmall"
      class="cursor-pointer"
      @click="() => onClickIcon()"
    />
  </div>
</template>

