<script setup>
import moment from 'moment';
import { DATE_FORMAT } from '@emobg/web-utils';

const props = defineProps({
  startDate: {
    type: String,
    default: '',
  },
  endDate: {
    type: String,
    default: '',
  },
  isBookingFinished: {
    type: Boolean,
    default: true,
  },
});

const startMomentDate = moment(props.startDate);
const endMomentDate = moment(props.endDate);
</script>

<template>
  <div class="BookingDateTemplate">
    <template v-if="!props.isBookingFinished || !startMomentDate.isValid() || !endMomentDate.isValid()">
      {{ FALLBACK_MESSAGE.dash }}
    </template>
    <template v-else>
      {{ startMomentDate.format(DATE_FORMAT.defaultExtended) }}
      <br>
      {{ endMomentDate.format(DATE_FORMAT.defaultExtended) }}
    </template>
  </div>
</template>
