import { useTranslations } from '@/composable/App/Translations';

import CompanyAdminRoutesNames from '@Profile/Business/CompanyAdmin/router/routes-names';
import EmployeeBookingsComponent from '@/components/CompanyAdmin/EmployeeBookingsComponent';
import EmployeeBookingsRequestsComponent from '@/components/CompanyAdmin/EmployeeBookingsRequestsComponent';

const { $t } = useTranslations();

export const computeEmployeeBookingTabs = ({ isBookingRequest }) => [
  {
    id: 'bookings',
    label: $t('business_profile.employee_bookings.tab_bookings'),
    content: [
      {
        component: EmployeeBookingsComponent,
      },
    ],
    url: CompanyAdminRoutesNames.employeeBookingsList,
    isVisible: true,
  },
  {
    id: 'bookings_request',
    label: $t('business_profile.employee_prebookings.tab'),
    content: [
      {
        component: EmployeeBookingsRequestsComponent,
      },
    ],
    url: CompanyAdminRoutesNames.employeeBookingRequests,
    isVisible: isBookingRequest,
  },
];
