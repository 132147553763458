<template>
  <MainViewLayout>
    <template slot="main-content">
      <div class="phLayoutSettings LayoutPage ">
        <div
          class="GenericTabs"
          :class="`GenericTabs--${currentProfile.isBusinessProfile() ? 'Business' : 'Personal'}`"
        >
          <MuiTabs
            :active="currentTabIndex"
            :tabs="getActiveTabs"
            class="TabsContent pt-5"
            @active-tab="goToTab"
          />
        </div>
      </div>
    </template>
  </MainViewLayout>
</template>

<script>
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';

import { MuiTabs } from '@emobg/vue-base';
import { aclService } from '@emobg/access-utils';
import { navigationErrorHandler } from '@emobg/web-utils';

import { VIEW_BOOKINGS_COMPANY_EMPLOYEES, VIEW_OWN_BEHALF_EMPLOYEE_BOOKINGS } from '@/constants/permissions';
import LoaderMixin from '@/mixins/Loader';
import CSOperatorMixin from '@/mixins/CSOperator';
import MainViewLayout from '@/templates/MainLayout/MainLayout';
import BookingRoutesNames from '@Bookings/router/routes-names';
import ProfileMixin from '@/mixins/Profile';
import { fetchBookingCostAllocation } from '@/stores/CostAllocation/CostAllocationMapper';

import { userData } from '@/stores/User/UserData/UserDataMapper';
import { computeEmployeeBookingTabs } from './helpers/employeeBookingsHelpers';

export default {
  name: 'EmployeeBookingsView',
  components: {
    MainViewLayout,
    MuiTabs,
  },
  mixins: [
    LoaderMixin,
    CSOperatorMixin,
    ProfileMixin,
  ],

  data() {
    return {
      isBookingRequest: false,
    };
  },

  computed: {
    userData,

    getActiveTabs() {
      const tabs = computeEmployeeBookingTabs({ isBookingRequest: this.isBookingRequest });
      return filter(tabs, 'isVisible');
    },
    currentTabIndex() {
      return findIndex(this.getActiveTabs, { url: this.$route.name });
    },
  },

  watch: {
    currentProfile() {
      this.checkPermissions();
    },
  },
  async created() {
    this.checkPermissions();
    // TODO: check await in case we can avoid, also in other .vue's. This can improve page load
    await Promise.all([
      this.fetchCsOperatorCall(),
      this.fetchCostAllocationCall(),
    ]).then(([csOperator]) => {
      this.checkPreBooking(csOperator);
    }).catch(() => {
      this.isBookingRequest = false;
    }).then(() => {
      this.toggleLoaderStatus(false);
    });
  },
  methods: {
    fetchBookingCostAllocation,

    fetchCsOperatorCall() {
      const companyOperatorUuid = get(this, 'userData.company.dedicated_fleet_cs_operator');
      return companyOperatorUuid ? this.fetchCSOperator(companyOperatorUuid) : undefined;
    },
    fetchCostAllocationCall() {
      const companyUuid = this.currentProfile.getCompanyData().getUUID();
      return this.canCurrentProfileUseCostAllocation
        ? this.fetchBookingCostAllocation({ companyUuid })
        : undefined;
    },
    checkPreBooking(csOperator) {
      this.isBookingRequest = get(csOperator, 'configuration.uses_pre_booking');
      if (!this.isBookingRequest) {
        this.$router
          .replace({ name: this.routes.employeeBookingsList })
          .catch(navigationErrorHandler);
      }
    },
    isRoute(routeName) {
      return this.$route.name === routeName;
    },
    goToTab(routeName) {
      this.$router.push({ name: routeName }).catch(navigationErrorHandler);
    },
    checkPermissions() {
      const businessProfile = this.currentProfile.isBusinessProfile();
      const hasPermission = aclService.hasPermissions(VIEW_BOOKINGS_COMPANY_EMPLOYEES)
        || aclService.hasPermissions(VIEW_OWN_BEHALF_EMPLOYEE_BOOKINGS);

      if (!(businessProfile && hasPermission)) {
        this.$router.replace({ name: BookingRoutesNames.home }).catch(navigationErrorHandler);
      }
    },
  },
};
</script>
