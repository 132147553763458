import isFunction from 'lodash/isFunction';
import result from 'lodash/result';
import { LOG_TYPE, logger } from '@emobg/web-utils';

export const selectText = node => {
  if (!node) {
    return false;
  }

  try {
    const textRange = result(window, 'document.body.createTextRange');

    if (textRange) {
      if (isFunction(textRange.moveToElementText) && isFunction(textRange.select)) {
        textRange.moveToElementText(node);
        textRange.select();
        return true;
      }
    }

    const selection = result(window, 'getSelection');
    const range = result(window, 'document.createRange');

    if (selection && range) {
      if (isFunction(range.selectNodeContents) && isFunction(selection.removeAllRanges) && isFunction(selection.addRange)) {
        range.selectNodeContents(node);
        selection.removeAllRanges();
        selection.addRange(range);
        return true;
      }
    }

    logger.message('Could not select text in node: Unsupported browser', LOG_TYPE.warning);
  } catch (error) {
    logger.message(`Could not select text in node: ${error.message}`, LOG_TYPE.error);
  }

  return false;
};

export const copySelectedText = () => {
  const document = (window && window.document) || {};

  if (!document.execCommand) {
    return;
  }

  document.execCommand('copy');
};

export const unselectText = () => {
  const selection = result(window, 'getSelection');

  if (!selection) {
    return;
  }

  if (isFunction(selection.removeAllRanges)) {
    selection.removeAllRanges();
  }
};
