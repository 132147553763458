<script>
import { CsMask } from '@emobg/vue-base';
import { ICONS, ICONS_SIZES } from '@emobg/web-components';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';

export default {
  name: 'BookingCreatorComponent',
  directives: {
    CsMask,
  },
  props: {
    fullName: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    masked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFullNameVisible() {
      return this.fullName || !(this.email || this.fullName);
    },
  },
  created() {
    this.ICONS = ICONS;
    this.ICONS_SIZES = ICONS_SIZES;
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;
  },
};
</script>

<template>
  <div class="BookingCreatorComponent">
    <div
      v-if="isFullNameVisible"
      v-cs-mask="masked"
    >
      {{ fullName || FALLBACK_MESSAGE.dash }}
    </div>
    <div
      v-if="email"
      class="d-flex justify-content-center"
    >
      <ui-icon
        :icon="ICONS.email"
        :size="ICONS_SIZES.xSmall"
        square
        class="mr-1"
      />
      <a
        v-cs-mask="masked"
        :href="`mailto:${email}`"
      >
        {{ email }}
      </a>
    </div>
  </div>
</template>
