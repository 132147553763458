import isEmpty from 'lodash/isEmpty';
import { getValue, isFalsyString } from '@emobg/web-utils';

import { allowsAdditionalDrivers, allowsOneWay, allowsPassengers } from '@/helpers/csOperator/configuration';
import { isActiveBooking, isDedicatedBooking } from './bookings';
import { getPermissionsOnBooking } from './permissions';

const isEditableBooking = (context, booking, circles) => {
  const { isEditable } = getPermissionsOnBooking(context, booking, circles);
  return isEditable && isDedicatedBooking(booking);
};

export const isAdditionalDriversEnabled = (
  context,
  booking,
  circles,
  operatorConfig,
) => isEditableBooking(context, booking, circles) && allowsAdditionalDrivers(operatorConfig);

export const isAdditionalPassengersEnabled = (
  context,
  booking,
  circles,
  operatorConfig,
) => isEditableBooking(context, booking, circles) && allowsPassengers(operatorConfig);

export const isOneWayEnabled = (
  context,
  booking,
  circles,
  operatorConfig,
) => isEditableBooking(context, booking, circles) && allowsOneWay(operatorConfig);

export const canAddVehicleUsers = (context, booking, circles, operatorConfiguration) => {
  const bookingUuid = getValue(booking, 'uuid');
  const isPrebooking = isFalsyString(getValue(booking, 'booking_uuid', undefined));
  let cacheData = getValue(context.cache, `booking_${bookingUuid}`, {});

  const activeCondition = isPrebooking
    ? true
    : isActiveBooking(getValue(booking, 'status', undefined));

  if (activeCondition && isEmpty(cacheData)) {
    const canAddDrivers = isAdditionalDriversEnabled(context, booking, circles, operatorConfiguration);
    const canAddPassengers = isAdditionalPassengersEnabled(context, booking, circles, operatorConfiguration);

    cacheData = {
      canAddDrivers,
      canAddPassengers,
    };

    context.$set(context.cache, `booking_${bookingUuid}`, cacheData);
  }

  return getValue(context.cache, `booking_${bookingUuid}`, {});
};
