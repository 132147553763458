<script setup>
import { computed } from 'vue';

import { parseAlgoliaCostAllocations } from '@/utils/costAllocationHelpers';

const props = defineProps({
  costAllocationValue: {
    type: Array,
    default: () => [],
  },
  costAllocationList: {
    type: Array,
    default: () => [],
  },
});

const costAllocations = computed(() => parseAlgoliaCostAllocations(props.costAllocationValue, props.costAllocationList));
</script>

<template>
  <div class="BookingCostAllocations">
    <template v-if="costAllocations.length">
      <div
        v-for="(costAllocation, index) in costAllocations"
        :key="index"
      >
        <b>{{ costAllocation.name }}</b>: {{ costAllocation.value }}
        <br v-if="costAllocations.length > 1">
      </div>
    </template>
    <span v-else>
      {{ FALLBACK_MESSAGE.dash }}
    </span>
  </div>
</template>
