export const facetsTypes = Object.freeze({
  rangeDateTimePicker: 'rangeDateTimePicker',
  refinementList: 'refinementList',
});

export const sortingTypes = Object.freeze({
  asc: 'asc',
  desc: 'desc',
});

export const DEFAULT_HITS_PER_PAGE = 50;
