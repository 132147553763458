import isEmpty from 'lodash/isEmpty';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import filter from 'lodash/filter';
import map from 'lodash/map';
import includes from 'lodash/includes';
import has from 'lodash/has';

import { aclService } from '@emobg/access-utils';
import { isFalsyString } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

import {
  CANCEL_CS_LD_BOOKING_ON_BEHALF_EMPLOYEES,
  EDIT_BOOKING_ON_BEHALF_EMPLOYEES,
  EDIT_OWN_BEHALF_EMPLOYEE_BOOKINGS,
} from '@/constants/permissions';
import { isActiveBooking } from './bookings';

export const canDoThat = (circles, permission, employeeUuid) => {
  if (aclService.hasUserPermissions(permission)) {
    return true;
  }

  const employeeCircles = filter(circles, circle => includes(get(circle, 'employees', []), employeeUuid));
  const circleUuids = map(employeeCircles, item => get(item, 'circleUuid', ''));

  return aclService.hasPermissions(permission, circleUuids);
};

export const getPermissions = (circles, userUuid) => {
  const isEditableBookingOnBehalf = canDoThat(circles, EDIT_OWN_BEHALF_EMPLOYEE_BOOKINGS, userUuid);
  const isEditable = canDoThat(circles, EDIT_BOOKING_ON_BEHALF_EMPLOYEES, userUuid) || isEditableBookingOnBehalf;
  const isCancelable = canDoThat(circles, CANCEL_CS_LD_BOOKING_ON_BEHALF_EMPLOYEES, userUuid) || isEditableBookingOnBehalf;
  return {
    isEditable,
    isCancelable,
  };
};

export const getCircles = async (notifyError, circleUuids) => {
  try {
    const apiCalls = map(
      circleUuids,
      circleUuid => external.userCompanyCircles.getEmployeesInCircles([circleUuid])
        .then(response => ({ circleUuid, employees: get(response, 'data', []) })),
    );
    return await Promise.all(apiCalls);
  } catch (error) {
    const message = get(error, 'response.data.message', error.message);
    notifyError({
      text: message,
      textAction: this.$t('modal.booking_confirm.ok'),
    });
    return undefined;
  }
};

export const getEmployeeUuids = circles => {
  const flatCircles = flatMap(circles, circle => get(circle, 'employees', []));
  return uniq(flatCircles);
};

export const getPermissionsOnBooking = (context, booking, circles) => {
  const isPrebooking = has(booking, 'booking_uuid')
    && isFalsyString(get(booking, 'booking_uuid'));

  const userUuid = isPrebooking
    ? get(booking, 'user.uuid')
    : get(booking, 'user_uuid');
  const cacheData = get(context.cache, `user_${userUuid}`, {});

  const activeCondition = isPrebooking
    ? true
    : isActiveBooking(get(booking, 'status'));

  if (activeCondition && isEmpty(cacheData)) {
    context.$set(context.cache, `user_${userUuid}`, getPermissions(circles, userUuid));
  }

  return get(context.cache, `user_${userUuid}`, cacheData);
};
