import { mapActions, mapGetters } from 'vuex';

import { NAMESPACE as CostAllocationNamespace } from '@/stores/CostAllocation/CostAllocationModule';
import * as ProfileCostAllocationModule from '@/stores/CostAllocation/Profile/ProfileCostAllocationModule';
import * as CompanyCostAllocationModule from '@/stores/CostAllocation/Company/CompanyCostAllocationModule';
import * as BookingCostAllocationModule from '@/stores/CostAllocation/Booking/BookingCostAllocationModule';

export const {
  getProfileCostAllocation,
  getCompanyProfileCostAllocations,
  fetchProfileCostAllocation,
  fetchCompanyProfileCostAllocations,
  updateProfileCostAllocation,
  isFetchCompanyProfileCostAllocationsLoading,
  isProfileCostAllocationsLoading,
  isFetchProfileCostAllocationsLoading,
  fetchBookingCostAllocation,
  fetchExternalBooking,
} = {

  ...mapGetters(`${CostAllocationNamespace}/${ProfileCostAllocationModule.NAMESPACE}`, {
    getProfileCostAllocation: ProfileCostAllocationModule.GETTERS.getProfileCostAllocations,
    isProfileCostAllocationsLoading: ProfileCostAllocationModule.GETTERS.isProfileCostAllocationsLoading,
    isFetchProfileCostAllocationsLoading: ProfileCostAllocationModule.GETTERS.isFetchProfileCostAllocationsLoading,
  }),

  ...mapGetters(`${CostAllocationNamespace}/${CompanyCostAllocationModule.NAMESPACE}`, {
    getCompanyProfileCostAllocations: CompanyCostAllocationModule.GETTERS.getCompanyProfileCostAllocations,
    isFetchCompanyProfileCostAllocationsLoading: CompanyCostAllocationModule.GETTERS.isFetchCompanyProfileCostAllocationsLoading,
  }),

  ...mapActions(`${CostAllocationNamespace}/${ProfileCostAllocationModule.NAMESPACE}`, {
    fetchProfileCostAllocation: ProfileCostAllocationModule.ACTIONS.fetchProfileCostAllocation,
    updateProfileCostAllocation: ProfileCostAllocationModule.ACTIONS.updateProfileCostAllocation,
  }),

  ...mapActions(`${CostAllocationNamespace}/${CompanyCostAllocationModule.NAMESPACE}`, {
    fetchCompanyProfileCostAllocations: CompanyCostAllocationModule.ACTIONS.fetchCompanyProfileCostAllocations,
  }),

  ...mapActions(`${CostAllocationNamespace}/${BookingCostAllocationModule.NAMESPACE}`, {
    fetchBookingCostAllocation: BookingCostAllocationModule.ACTIONS.fetchBookingCostAllocation,
    fetchExternalBooking: BookingCostAllocationModule.ACTIONS.fetchExternalBooking,
  }),

};
