<script>
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';

export default {
  name: 'AlgoliaTableItemActions',
  props: {
    algoliaContext: {
      type: Object,
      default: () => ({}),
    },
    rowActions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    dropdownActions() {
      return filter(map(this.rowActions, (action) => ({
        label: isFunction(action.label) ? action.label(this.algoliaContext) : action.label,
        action: () => action.method(this.algoliaContext),
        isVisible: (() => action.isVisible(this.algoliaContext))(),
        labelClass: action.class,
      })), 'isVisible');
    },
  },
  methods: { isEmpty },
};
</script>

<template>
  <div
    v-if="!isEmpty(dropdownActions)"
    class="TableItemActions float-right"
  >
    <ui-dropdown
      :color="GRAYSCALE.white"
      :placement="PLACEMENTS.left"
    >
      <ui-button
        slot="trigger"
        :color="GRAYSCALE.white"
        contrast
        square
      >
        <ui-icon
          :size="ICONS_SIZES.small"
          :color="GRAYSCALE.ink"
          :icon="ICONS.optionsHorizontalFull"
        />
      </ui-button>
      <ui-dropdown-actions
        slot="content"
        :actions.prop="dropdownActions"
      />
    </ui-dropdown>
  </div>
</template>
