<script>
import toLower from 'lodash/toLower';
import { COLORS, GRAYSCALE } from '@emobg/web-components';

import { BOOKING_STATUS } from '@/constants/bookingStatus.const';
import { BOOKING_USAGE_STATUS } from '@/constants/bookingUsageStatus.const';

const BADGE_CLASSES = {
  [BOOKING_STATUS.booked]: COLORS.success,
  [BOOKING_STATUS.active]: COLORS.success,
  [BOOKING_STATUS.finished]: COLORS.success,
  [BOOKING_STATUS.notUsed]: COLORS.success,
  [BOOKING_STATUS.cancelled]: COLORS.danger,
  [BOOKING_STATUS.inactive]: COLORS.danger,
  fallback: GRAYSCALE.ground,
};

export default {
  name: 'BookingStatusTemplate',
  props: {
    result: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    badgeColorClass() {
      return BADGE_CLASSES[this.result.status] || BADGE_CLASSES.fallback;
    },
    displayUsageStatus() {
      return toLower(this.result.status) === BOOKING_STATUS.cancelled
        && toLower(this.result.usage_status) === BOOKING_USAGE_STATUS.notUsed;
    },
  },
};
</script>

<template>
  <div class="d-inline-flex flex-column">
    <ui-badge :color="badgeColorClass">
      {{ $t(`booking_status.${result.status}`, result.status) }}
    </ui-badge>
    <ui-badge
      v-if="displayUsageStatus"
      :color="GRAYSCALE.ground"
    >
      {{ $t(`booking_usage_status.${result.usage_status}`, result.usage_status) }}
    </ui-badge>
  </div>
</template>
