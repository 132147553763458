export const BOOKING_FIELDS = Object.freeze({
  name: 'name',
  startTzTs: 'start_tz_ts',
  endTzTs: 'end_tz_ts',
  status: 'status',
  fleet: 'fleet',
  city: 'city',
  location: 'location',
  vehicleCategory: 'vehicle_category',
  vehiclePlate: 'vehicle_plate',
  userName: 'user_name',
  employeeEmail: 'employee_email',
  employeePhone: 'employee_phone',
  creatorEmail: 'creator.email',
  additionalDriver: 'additional_driver',
  companyCostAllocations: 'company_cost_allocations',
});
