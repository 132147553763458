export const PREBOOKING_FIELDS = Object.freeze({
  cityName: 'location.city.name',
  locationName: 'location.name',
  user: 'user',
  location: 'location',
  destinationLocation: 'destination_location',
  bookingDuration: 'booking_duration',
  csBookingUseCase: 'cs_booking_use_case',
  distance: 'distance',
  vehicleCategory: 'vehicle_category',
});
