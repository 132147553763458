import isPlainObject from 'lodash/isPlainObject';
import pick from 'lodash/pick';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import some from 'lodash/some';
import has from 'lodash/has';
import {
  LOG_TYPE,
  camelCaseKeys,
  isFalsyString,
  logger,
} from '@emobg/web-utils';

import {
  getAdditionalDrivers,
  getBookingUuid,
  getBookingVehicleSeats,
  getPassengers,
  getUserUuid,
} from '@/helpers/booking/bookingHelpers';

// Constants
import { FLEET_TYPES } from '@/constants/fleetTypes.const';
import { PROFILE_TYPES } from '@/constants/profiles.const';

import { BOOKING_TYPES } from '@/constants/bookingTypes';

import { concatFilterByPermission, generateFilterBy, groupFiltersBy } from '@/utils/algoliaHelpers';
import { ALGOLIA_FILTERS_OPERATORS } from '@/constants/algoliaFilters';

import { ERROR_MESSAGES } from '@/handlers/errorHandler.const';

const toEditTimeData = ({
  bookingType,
  start,
  end,
  operatorUuid,
  isBookingRequest,
  vehicleCategoryUuid,
  uuid,
  locationUuid,
  vehicleUuid,
  destinationLocationUuid,
  tags,
}) => ({
  cs_booking_use_case: {
    booking_type: bookingType,
  },
  start,
  end,
  cs_operator_uuid: operatorUuid,
  isBookingRequest,
  vehicle_category: {
    uuid: vehicleCategoryUuid,
  },
  uuid,
  vehicle: {
    uuid: vehicleUuid,
  },
  location: {
    original_uuid: locationUuid,
  },
  destination_location: {
    uuid: destinationLocationUuid,
  },
  tags,
});

export const getEditBookingTime = booking => {
  if (!isPlainObject(booking)) {
    throw new Error(ERROR_MESSAGES.bookingIsNotObject);
  }

  const isPrebooking = get(booking, 'isPreBooking') || (has(booking, 'booking_uuid') && isFalsyString(get(booking, 'booking_uuid')));
  const sanitizedBooking = {
    ...camelCaseKeys(
      pick(booking, ['uuid', 'start', 'end', 'tags', 'location_uuid', 'vehicle_uuid']),
    ),
    bookingType: booking.booking_type,
    operatorUuid: booking.cs_operator_uuid,
    isBookingRequest: isPrebooking,
    vehicleCategoryUuid: '',
    destinationLocationUuid: get(booking, 'destination_location.uuid'),
  };

  if (isPrebooking) {
    sanitizedBooking.bookingType = get(booking, 'cs_booking_use_case.booking_type');
    sanitizedBooking.vehicleCategoryUuid = get(booking, 'vehicle_category.uuid');
    sanitizedBooking.locationUuid = get(booking, 'location.uuid');
    sanitizedBooking.tags = [];
    sanitizedBooking.vehicleUuid = null;
  }

  return toEditTimeData(sanitizedBooking);
};

export const getEditBookingReturnLocation = booking => {
  if (!isPlainObject(booking)) {
    throw new Error(ERROR_MESSAGES.bookingIsNotObject);
  }
  const isPrebooking = get(booking, 'isPreBooking') || (has(booking, 'booking_uuid') && isFalsyString(get(booking, 'booking_uuid')));

  return {
    ...pick(booking, ['uuid', 'start', 'end', 'destination_location.uuid']),
    csOperator: {
      uuid: booking.cs_operator_uuid,
    },
    location: {
      original_uuid: isPrebooking
        ? get(booking, 'location.uuid')
        : get(booking, 'location_uuid'),
    },
    isBookingRequest: isPrebooking,
  };
};

export const toEditBookingPeopleData = booking => {
  if (!isPlainObject(booking)) {
    throw new Error(ERROR_MESSAGES.bookingIsNotObject);
  }

  return {
    drivers: getAdditionalDrivers({ booking, fromAlgolia: true }),
    passengers: getPassengers({ booking, fromAlgolia: true }),
    availableSeats: getBookingVehicleSeats({ booking, fromAlgolia: true }),
    ownerEmail: get(booking, 'user.email'),
    userUuid: getUserUuid(booking),
    bookingUuid: getBookingUuid({ booking, fromAlgolia: true }),
    bookingType: get(
      booking,
      'cs_booking_use_case.booking_type',
      BOOKING_TYPES.carsharing,
    ),
  };
};

// TODO: [SBCSB-2033]  Align filters for Bookings / Prebookings using company_uuid instead of cs_operator
// Required BE task
export const employeeBookingsFilters = ({
  csOperatorUuid, companyUuid, circlesEmployees, creatorUuid, ownerUuid, isPreBooking = false,
}) => {
  let algoliaFilters = '';

  if (isPreBooking) {
    if (isEmpty(csOperatorUuid)) {
      logger.message('Algolia filter required for Prebookings csOperatorUuid is missing', LOG_TYPE.danger);
      throw new Error('Missing csOperatorUuid data to filter properly');
    }
    const bookingIsNull = "booking_uuid:'null'";
    algoliaFilters = `cs_operator_uuid:'${csOperatorUuid}' AND ${bookingIsNull}`;
  } else {
    if (isEmpty(companyUuid)) {
      logger.message('Algolia filter required for Bookings companyUuid is missing', LOG_TYPE.danger);
      throw new Error('Missing companyUuid data to filter properly');
    }
    const bookingTypes = `NOT booking_type:'${BOOKING_TYPES.intervention}'`;
    algoliaFilters = `company_uuid:'${companyUuid}' AND ${bookingTypes}`;
  }

  const fleet = `${isPreBooking ? 'cs_booking_use_case.' : ''}fleet:'${FLEET_TYPES.dedicated}'`;
  const profile = `${isPreBooking ? 'cs_booking_use_case.' : ''}profile:'${PROFILE_TYPES.business}'`;
  algoliaFilters = `${algoliaFilters} AND (${fleet} OR ${profile})`;

  const userUuidField = isPreBooking
    ? 'user.uuid'
    : 'user_uuid';

  if (circlesEmployees) {
    algoliaFilters = concatFilterByPermission({
      currentFilter: algoliaFilters,
      circlesUuids: circlesEmployees,
      field: userUuidField,
    });
  }

  const creatorUuidFilter = generateFilterBy('creator.uuid', [creatorUuid]);
  const ownerUuidFilter = generateFilterBy(userUuidField, [ownerUuid]);

  const onBehalfAdminConditions = groupFiltersBy([creatorUuidFilter, ownerUuidFilter], ALGOLIA_FILTERS_OPERATORS.or);

  algoliaFilters = `${algoliaFilters} ${isEmpty(onBehalfAdminConditions) ? '' : `${ALGOLIA_FILTERS_OPERATORS.and} ${onBehalfAdminConditions}`}`;

  return trim(algoliaFilters);
};

export const creatorFacetSorter = (currentCreator, nextCreator, userEmail) => {
  if (some([currentCreator, nextCreator, userEmail], isEmpty)) {
    const errorMessage = 'Missing mandatory parameter: currentCreator, nextCreator and userEmail must be provided';
    logger.message(errorMessage, LOG_TYPE.danger);
    throw new Error(errorMessage);
  }
  const currentCreatorIsUser = currentCreator.name === userEmail;
  const nextCreatorIsUser = nextCreator.name === userEmail;

  const hasMoreBookings = currentCreator.count > nextCreator.count;
  const hasLessBookings = currentCreator.count < nextCreator.count;

  if (hasMoreBookings) {
    return currentCreatorIsUser ? -1 : 1;
  }

  if (hasLessBookings) {
    return nextCreatorIsUser ? 1 : -1;
  }

  return 0;
};
