<script setup>
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

const props = defineProps({
  locationAddress: {
    type: String,
    default: '',
  },
  locationName: {
    type: String,
    default: '',
  },
});

const getLinkToGoogleMaps = () => `https://maps.google.com/?q=${isNil(props.locationAddress) ? '' : encodeURI(props.locationAddress)}`;
</script>

<template>
  <div class="BookingLinkLocationAddress">
    <template v-if="isEmpty(props.locationAddress) && isEmpty(props.locationName)">
      {{ FALLBACK_MESSAGE.dash }}
    </template>
    <template v-else>
      <span v-if="isEmpty(locationAddress)">
        {{ props.locationName }}
      </span>
      <a
        v-else
        :href="getLinkToGoogleMaps(props.locationAddress)"
        target="_blank"
      >
        {{ props.locationAddress }}
      </a>
    </template>
  </div>
</template>
